<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer" [object]="object" [searchable]="iiifSearchEnabled" [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>

<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <ds-dso-edit-menu></ds-dso-edit-menu>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!(mediaViewer.image || mediaViewer.video)">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image || mediaViewer.video" class="mb-2">
      <ds-themed-media-viewer [item]="object"></ds-themed-media-viewer>
    </div>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object"
    [itemType]="'Person'" [metadataFields]="['dc.contributor.author', 'dc.creator']"
    [label]="'relationships.isAuthorOf' | translate">
  </ds-themed-metadata-representation-list>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object"
      [itemType]="'Person'" [metadataFields]="['dc.contributor.advisor']"
      [label]="'relationships.isAdvisorOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object"
      [itemType]="'Person'" [metadataFields]="['dc.degree.grantor']"
      [label]="'relationships.isGrantorOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object"
      [itemType]="'Person'" [metadataFields]="['dc.degree.program']"
      [label]="'relationships.isProgramOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object"
      [itemType]="'Person'" [metadataFields]="['dc.degree.local']"
      [label]="'relationships.isLocalOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-generic-item-page-field [item]="object" [fields]="['journal.title']" [label]="'item.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['journal.identifier.issn']"
      [label]="'item.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['journalvolume.identifier.name']"
      [label]="'item.page.volume-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.publisher']" [label]="'item.page.publisher'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.description']" [label]="'item.page.description'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object" [fields]="['dc.subject']" [separator]="', '"
      [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.identifier.citation']" [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.uri']" [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
  </div>
  <div class="col-12 my-4 text-center">
    <small>
      Este item está licenciado na <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"
        target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-NC-SA 4.0
        <img
          style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
        <img
          style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
        <img
          style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1">
        <img
          style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1">
      </a>
    </small>
  </div>
  <div class="col-12 text-center">
    <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']" role="button">
      <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
    </a>
  </div>
</div>